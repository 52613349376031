import React from 'react';
import Classnames from 'classnames';
import { ParagraphSecondary } from '../typography';
import { TypographicSizes } from '../../../slices/common-slice';

import './how-it-works-list-item.scss';

export enum HowItWorksListItemTypes {
    SOLID = 'solid',
    ICON = 'icon',
}

interface HowItWorksListItemProps {
    description: string;
    displayType: HowItWorksListItemTypes;
    headline?: string;
    number: number;
}

export const HowItWorksListItem: React.FC<HowItWorksListItemProps> = ({
    children,
    description,
    displayType,
    headline,
    number,
}): React.ReactElement => {
    const classes = Classnames('how-it-works-list-item', { 'how-it-works-list-item--has-children': children });

    const tagClasses = Classnames('how-it-works-list-item__tag', {
        [`how-it-works-list-item__tag--${displayType}`]: displayType,
    });

    return (
        <li className={classes}>
            <div className={tagClasses}>
                {displayType === HowItWorksListItemTypes.ICON && (
                    <i
                        data-testid="how-it-works-list-item-icon"
                        className="how-it-works-list-item__icon fa-kit fa-prizeout-tag-solid"
                    ></i>
                )}
                <span className="how-it-works-list-item__number">{number}</span>
            </div>

            <div className="how-it-works-list-item__message">
                {headline && (
                    <p className="how-it-works-list-item__headline">
                        <strong>{headline}</strong>
                    </p>
                )}
                <ParagraphSecondary text={description} size={children ? TypographicSizes.TWO : TypographicSizes.ONE} />
            </div>

            {children && <div className="how-it-works-list-item__children">{children}</div>}
        </li>
    );
};
