import React from 'react';
import { CashbackOnboardingIntro } from './cashback-onboarding-intro';
import { Banner } from './banner';
import { CashbackOnboardingSteps } from './cashback-onboarding-steps';
import { Button, ButtonColor, ButtonSize, ButtonType } from '../buttons';
import { BaseCRMModalData } from '../../../slices/crm';
import { useFindWidgetRoutes } from '../../../hooks/use-find-widget-routes';
import { useAppSelector, useGoToRoute } from '../../../hooks';
import { selectCBOnboaringTestimonial } from '../../../slices/partner-config-slice';

import './cashback-onboarding.scss';
import { routes } from '../../../utils';

interface CashbackOnboardingProps {
    onClose?: () => void;
    data?: BaseCRMModalData;
}

export const CashbackOnboarding: React.FC<CashbackOnboardingProps> = ({ onClose, data }): React.ReactElement => {
    const bannerText = 'Get paid to do your everyday shopping with gift cards';
    const selectorTestimonial = useAppSelector(selectCBOnboaringTestimonial);
    const testimonial = data?.testimonial || selectorTestimonial;
    const { primaryCTA } = useFindWidgetRoutes(data?.cta_list);
    const { goToRoute } = useGoToRoute();

    const handlePrimaryClick = (url: routes) => {
        if (!url || url === location.pathname) {
            onClose();
            return;
        }

        goToRoute(url);
    };

    return (
        <div className="cashback-onboarding">
            <CashbackOnboardingIntro exitHandler={onClose} data={testimonial} />
            <Banner text={bannerText} />
            <div className="cashback-onboarding__inner">
                <CashbackOnboardingSteps />
                <Button
                    ariaLabel="Get started"
                    size={ButtonSize.MEDIUM}
                    text={primaryCTA?.cta_label || 'Get started'}
                    color={ButtonColor.PRIMARY}
                    type={ButtonType.BUTTON}
                    testId="onboarding-cta"
                    onClick={() => handlePrimaryClick(primaryCTA?.cta_url)}
                />
            </div>
        </div>
    );
};
