export const isInputElement = (element: Element): element is HTMLInputElement => {
    return element instanceof HTMLInputElement;
};

export const isElement = (element: Element | Document): element is Element => {
    return element instanceof Element;
};

export const isNumber = (value: unknown): value is number => {
    return value && typeof value === 'number';
};

export const isString = (value: unknown): value is string => {
    return value && typeof value === 'string';
};

export const isNotDefined = (value: unknown): boolean => {
    return value === null || value === undefined;
};
