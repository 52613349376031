import React from 'react';
import { BonusTag, GiftCardImage, GiftCardImageView, RoundImage } from '../../../../../../components/common';
import { constants } from '../../../../../../utils/constants';
import { CurrentOfferDisplay } from '../offer-gift-card';
import { OfferGiftCardOverlay } from '../common/offer-gift-card-overlay';
import { OfferName } from '../common/offer-name';
import { RoundImageSizes } from '../../../../../../slices/common-slice';

import './hero-offer-display.scss';

interface HeroOfferDisplayProps {
    data: CurrentOfferDisplay;
}

export const HeroOfferDisplay: React.FC<HeroOfferDisplayProps> = ({ data }): React.ReactElement => {
    return (
        <>
            <OfferGiftCardOverlay
                giftCardImage={
                    <GiftCardImage imgUrl={data.hero_image_url} altText={`{data.name}`} view={GiftCardImageView.HERO} />
                }
                hasGradient
            >
                <RoundImage
                    imageUrl={data.logomark_url || constants.defaultRoundImageUrl}
                    imageAltText={`${data.name} logomark`}
                    size={RoundImageSizes.TWO}
                />

                <OfferName name={data.name} rows={1} isBold />

                {data.offer_value > 0 && (
                    <BonusTag
                        type={data.offer_type}
                        value={data.offer_value}
                        currency={data.currency}
                        offerType={data.display_offer_type}
                    />
                )}
            </OfferGiftCardOverlay>
        </>
    );
};
